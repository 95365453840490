
import Map from './Map';

const Information = ({ receptionDateStr, ceremonyTime, receptionTime, hallDetail }) => {
  return (
    <div className="information-card">
        <h1>Information</h1>
        <div className='information-area'>
          <div className="information-date">
            <p>日付：{receptionDateStr}</p>
            <p>挙式：{ceremonyTime}</p>
            <p>披露宴：{receptionTime}</p>
          </div>
          <div className="information-hallDetail">
            <h2>{hallDetail.name}</h2>
            <a href={hallDetail.url}>jacksongardens</a>
            <p>{hallDetail.tel}</p>
            <p>{hallDetail.address}</p>
          </div>
          <div className="information-map">
            <Map
              hallDetail={hallDetail}
            />
          </div>

        </div>
    </div>
  )
}

export default Information
