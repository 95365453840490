import './App.css';
import Layout from './components/body/Layout';


function App() {
  return (
    <div id='app'>
      <Layout  />
    </div>
  );
}

export default App;
