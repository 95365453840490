import { useRef } from "react";
import Body from "../Body"
import Footer from "../Footer"
import Header from "../Header"

const Layout = () => {

  // 基礎情報設定
  const groomName = "新郎 太郎"; // 新郎漢字フルネーム
  const brideName = "新婦 花子"; // 新婦漢字フルネーム
  const groomFirstName = "Groom"; // 新郎アルファベットfirstName
  const brideFirstName = "Bride"; // 新婦アルファベットfirstName
  const receptionDateTimeStr = "2025-12-12T12:00:00"; // 「yyyy-mm-ddTHH:mm:ss」形式で目標を設定する
  const ceremonyTime = "12時00分（受付 11時30分）";
  const receptionTime = "13時00分（受付 12時30分）";
  const hallDetail = {
    name: "The Jackson Garden",
    url: "https://www.bridal-produce.co.jp/bridal/osaka/jacksongarden/",
    tel: "06-6485-3230",
    address: "大阪府大阪市北区大深町3-1 グランフロント大阪"
  }

  // スクロール先のRefを定義
  const memoriesRef = useRef(null);
  const profileRef = useRef(null);
  const messageRef = useRef(null);
  const countdownRef = useRef(null);
  const informationRef = useRef(null);
  const formRef = useRef(null);
  const refs = {
    memoriesRef,
    profileRef,
    messageRef,
    countdownRef,
    informationRef,
    formRef
  }

  // 要素を引数に渡したらその位置までスクロールする関数
  const scrollToElement = (ele) => {
    // スクロール先の要素の位置を取得
    const rect = ele.getBoundingClientRect();
    // スクロール先の要素の位置を調整
    const offset = 50;
    const top = rect.top + window.scrollY - offset;
    // スクロールの実行
    window.scrollTo({ top, behavior: 'smooth'});
  }

  // Navメニュークリック時に行う処理
  const handleSelectButton = async(selectButton) => {
    switch(selectButton) {
      case "top":
        window.scrollTo({ top: 0, behavior: 'smooth'});
        break;
      case "memories":
        scrollToElement(memoriesRef.current);
        break;
      case "profile":
        scrollToElement(profileRef.current);
        break;
      case "message":
        scrollToElement(messageRef.current);
        break;
      case "countdown":
        scrollToElement(countdownRef.current);
        break;
      case "information":
        scrollToElement(informationRef.current);
        break;
      case "form":
        scrollToElement(formRef.current);
        break;
      default:
        break;
    }
  }


    /// Topアイコンが押された場合
    // selectButton === "top" ? window.scrollTo({ top: 0, behavior: 'smooth'}):
    // if(selectButton === "top"){
    //     window.scrollTo({ top: 0, behavior: 'smooth'});
    // }
    /// トップページでNavが押された場合
    /// スクロールを行う処理を発火
    // selectButton === "memories" ? scrollToElement(memoriesRef.current):
    // selectButton === "profile" ? scrollToElement(profile.current):
    // selectButton === "message" ? scrollToElement(messageRef.current):
    // selectButton === "countdown" ? scrollToElement(countdownRef.current):
    // selectButton === "information" ? scrollToElement(informationRef.current):
    // selectButton === "form" ? scrollToElement(formRef.current):

  //   switch(selectButton) {
  //     case "memories":
  //         scrollToElement(memoriesRef.current);
  //         break;
  //     case "profile":
  //         scrollToElement(profile.current);
  //         break;
  //     case "message":
  //         scrollToElement(messageRef.current);
  //         break;
  //     case "countdown":
  //         scrollToElement(countdownRef.current);
  //         break;
  //     case "information":
  //         scrollToElement(informationRef.current);
  //         break;
  //     case "form":
  //         scrollToElement(formRef.current);
  //         break;
  //     default:
  //         // デフォルトの処理（何もしない場合も明示的に）
  //         break;
  // }


    // if(selectButton === "memories"){
    //     scrollToElement(memoriesRef.current);
    // }
    // if(selectButton === "profile"){
    //     scrollToElement(profile.current);
    // }
    // if(selectButton === "message"){
    //     scrollToElement(messageRef.current);
    // }
  // }

  return (
    <>
      <Header
        handleSelectButton={handleSelectButton}
      />
      <Body
        groomName={groomName}
        brideName={brideName}
        groomFirstName={groomFirstName}
        brideFirstName={brideFirstName}
        receptionDateTimeStr={receptionDateTimeStr}
        ceremonyTime={ceremonyTime}
        receptionTime={receptionTime}
        hallDetail={hallDetail}
        // Refをオブジェクトとして渡す
        refs={refs}
      />
      <Footer
        groomFirstName={groomFirstName}
        brideFirstName={brideFirstName}
      />
    </>
  )
}

export default Layout
