import React, { useState } from 'react';
import '../Body.css';
import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';


const Map = ({ hallDetail }) => {

    // GoogleMapコンポーネントのcenterプロパティを直接定数で定義すると、そこから中心を移動できなくなる。
    // 状態変数で持たせることによって、なぜか地図が初期中心箇所に戻らなくなる。
    // eslint-disable-next-line
    const [center, setCenter] = useState({ lat: 34.7030, lng: 135.4917 });
    const [selected, setSelected] = useState(null);
    const url = hallDetail.url;
    const name = hallDetail.name;


    const mapStyles = {
        height: "100%",
        width: "100%"
    };

    // 初期表示時のマーカー位置
    const defaultLocations = {
        name: name,
        url: url,
        location: {
            lat: 34.7030,
            lng: 135.4917
        }
    };
    // マーカー選択時の処理
    const handleMarkerClick = () => {
        setSelected(defaultLocations);
    };
    // InfoWindowのリンククリック時処理
    const handleClickButton = () => {
        console.log("url : ", url);
        window.open(url);
    }

    return (
        <LoadScript
            googleMapsApiKey={process.env.React_APP_GoogleMap_API_KEY}
        >
            <GoogleMap
                key={`${center.lat}-${center.lng}`}
                mapContainerStyle={mapStyles}
                zoom={14}
                center={center}
                options={{
                    mapTypeControl: false,
                    streetViewControl: false,
                    gestureHandling: 'auto', // ジェスチャー操作を許可
                    draggable: true, // ドラッグによる移動を許可
                }}
            >
            <Marker
                position={defaultLocations.location}
                onClick={handleMarkerClick}
            />
            {selected && (
                <InfoWindow
                    position={selected.location}
                    onCloseClick={() => setSelected(null)}
                    closeButtonOptions={{
                        // カスタムスタイルを適用
                        // style: {
                        //     padding: "0px", // パディングを追加してサイズを調整
                        //     height: 10,
                        //     cursor: "pointer", // カーソルをポインターに変更
                        // }
                    }}
                >
                    <div className="map-infoWindow">
                        <button onClick={handleClickButton}>{name}</button>
                        {/* <a href={url}  target="_blank" rel="noopener noreferrer">{name}</a> */}
                    </div>
                </InfoWindow>
            )}
            </GoogleMap>
        </LoadScript>
    )
}

export default Map
